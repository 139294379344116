import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./genericowner.component.css";
// import CatDog from "../../assets/user.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { switchUrls } from "../../api/index.js";

const baseURL = switchUrls("generic");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class GenericOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      genericowner_imagelocation: null,
      tag_number: "",
      owner_firstname: "",
      owner_lastname: "",
      owner_phone: "",
      owner_phonecode: "",
      owner_altphone: "",
      owner_altphonecode: "",
      owner_email: "",
      owner_altemail: "",
      owner_address: "",
      owner_country: "",
      owner_state: "",
      owner_city: "",
      owner_pincode: "",
      usertoken: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    axios
      .get(
        `${baseURL}/api/generic/getGenericDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          genericowner_imagelocation: response.data.genericowner_imagelocation,
          tag_number: response.data.tag_number,
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          owner_phone: response.data.owner_phone,
          owner_phonecode: response.data.owner_phonecode,
          owner_altphone: response.data.owner_altphone,
          owner_altphonecode: response.data.owner_altphonecode,
          owner_email: response.data.owner_email,
          owner_altemail: response.data.owner_altemail,
          owner_address: response.data.owner_address,
          owner_country: response.data.owner_country,
          owner_state: response.data.owner_state,
          owner_city: response.data.owner_city,
          owner_pincode: response.data.owner_pincode,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState({
      keyowner_imagelocation: URL.createObjectURL(event.target.files[0]),
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(
        `${baseURL}/api/generic/editGenericParentDetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      genericowner_imagelocation,
      tag_number,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_phonecode,
      owner_altphone,
      owner_altphonecode,
      owner_email,
      owner_altemail,
      owner_address,
      owner_country,
      owner_state,
      owner_city,
      owner_pincode,
    } = this.state;
    return (
      <div className="background p-5 bg-warning">
        <div className="container body-login bg-white mb-5">
          <div className="row" id="pet-details-fill-2">
            <div className="col-lg-4 p-5 col-sm col-md-4 left-side bg-dark">
              <div className="image-upload text-center mt-5">
                <img
                  alt=""
                  src={"https://storage.googleapis.com/pettag/qr/user.png"}
                  className="image"
                />
                <div className="overlay text-center">
                  <button
                    className="button-image"
                    id="profile-image-uploadActi"
                  >
                    <i className="fa fa-edit fa-2x"></i>
                  </button>
                  <button className="text-white button-image">
                    <i className="fa fa-trash fa-2x"></i>
                  </button>
                </div>
                <input
                  type="file"
                  onChange={this.handleChange}
                  id="choose-profile-imageActi"
                />
                <img
                  alt=""
                  id="uploadPreviewActi"
                  src={genericowner_imagelocation}
                />
              </div>
              <div className="text-center mt-2">
                <button
                  className="button-image text-white"
                  id="profile-image-uploadActi-1"
                >
                  Add Image<i className="fa fa-plus ml-3"></i>
                </button>
              </div>
              {/* <div className="row mt-5">
                                <div className="col">
                                    <ul className="mt-5 text-center leftside-navigation">
                                        <li>
                                            <button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important', 'border': '1px solid white','width': '200px'}}>Pet Parent Details</button><br/><br/>
                                        </li>
                                        <li>
                                            <button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important', 'border': '1px solid white','width': '200px'}}>Pet Medical Details</button><br/><br/>
                                        </li>
                                        <li>
                                            <button className="text-white bg-dark leftside-buttons py-1 px-4" style={{'fontSize': '16px !important', 'border': '1px solid white','width': '200px'}}>Dashboard</button><br/><br/>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
            </div>
            <div className="col-lg-8 col-md-8 col-sm px-3 py-4">
              <div className="row text-center px-4">
                <div className="col row-underline">
                  <p>
                    <strong>Generic Owner Details</strong>
                  </p>
                </div>
              </div>
              <div className="mx-4 mt-4 mb-3">
                <form onSubmit={this.submitHandler}>
                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Owner ID</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        value={tag_number}
                        name="tag_number"
                        readOnly
                      />
                      <br />
                      {/* <span className="input-pet-details">PET111</span> */}
                    </div>
                    <br />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Owner First Name</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_firstname"
                        value={owner_firstname}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">Owner Last Name</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_lastname"
                        value={owner_lastname}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Country Code</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_phonecode"
                        value={owner_phonecode}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">Phone Number</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_phone"
                        value={owner_phone}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Country Code</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_altphonecode"
                        value={owner_altphonecode}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">
                        Altername Phone Number
                      </span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_altphone"
                        value={owner_altphone}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Email</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_email"
                        value={owner_email}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">Altername Email</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_altemail"
                        value={owner_altemail}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  {/* <div className="row">
                                        <div className="col">
                                            <span className="label-petdata">Gender</span><br/>
                                            <input type="textbox" className="input-pet-details" maxLength="20" name="pet_name" value={} onChange={this.changeHandler} />
                                        </div><br/>
                                    </div><br/> */}

                  {/* <div className="row">
                                        <div className="col">
                                            <span className="label-petdata">Address</span><br/>
                                            <input type="textbox" className="input-pet-details-1" name="owner_address" value={owner_address} onChange={this.changeHandler} />
                                        </div><br/>
                                    </div><br/> */}

                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">Country</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_country"
                        value={owner_country}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">State</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_state"
                        value={owner_state}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col">
                      <span className="label-petdata">City</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_city"
                        value={owner_city}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                    <div className="col">
                      <span className="label-petdata">Pin Code</span>
                      <br />
                      <input
                        type="textbox"
                        className="input-pet-details"
                        maxLength="20"
                        name="owner_pincode"
                        value={owner_pincode}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <br />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        SUBMIT
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2"
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
